<template>
  <div class="home">
    <div class="page_header">
      <img src="https://pt-starimg.didistatic.com/static/starimg/img/0FYpm5NuMA1636700107154.png" alt="">
    </div>
    <div class="banner">
      <img src="https://pt-starimg.didistatic.com/static/starimg/img/2LPGW9haFz1616819925263.png" alt="">
    </div>
<!--    <div class="banner">-->
<!--      <img src="https://pt-starimg.didistatic.com/static/starimg/img/RdlbNZlqqP1638154209822.png" alt="">-->
<!--    </div>-->
<!--    <div class="banner">-->
<!--      <img src="https://pt-starimg.didistatic.com/static/starimg/img/c8NsWUyoNo1638155228309.png" alt="">-->
<!--    </div>-->
<!--    <div class="card">-->
<!--      <div class="item">-->
<!--        <div class="left">-->
<!--          <div class="l_text1">更省心的运货搬家体验</div>-->
<!--          <div class="l_text2">从滴滴出行APP-运货搬家下单</div>-->
<!--          <div class="l_text3">透明计价更实在， 安全运送有保障</div>-->
<!--          <div class="l_text3">让您运货搬家更省心</div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <img src="https://pt-starimg.didistatic.com/static/starimg/img/EAvGyACPNw1638154433635.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="item">-->
<!--        <div class="left">-->
<!--          <div class="l_text1">使用场景</div>-->
<!--          <div class="l_text2">拉货</div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <img src="https://pt-starimg.didistatic.com/static/starimg/img/58GcHu79Pd1636699060216.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="item">-->
<!--        <div class="left">-->
<!--          <div class="l_text1">使用场景</div>-->
<!--          <div class="l_text2">搬家</div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <img src="https://pt-starimg.didistatic.com/static/starimg/img/kRO3zG4Y4f1636699060714.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--    <div class="setting_card">-->
    <!--      <div class="item" @click="huozhu">-->
    <!--        <img src="https://pt-starimg.didistatic.com/static/starimg/img/ciZOmfdPv31616820529654.png" alt="">-->
    <!--      </div>-->
    <!--      <div class="item" @click="driverVisible = true">-->
    <!--        <img src="https://pt-starimg.didistatic.com/static/starimg/img/XExo3dOZ941616820529939.png" alt="">-->
    <!--      </div>-->
    <!--      <div class="item" @click="pingtai">-->
    <!--        <img src="https://pt-starimg.didistatic.com/static/starimg/img/OhbHHhushs1616820529658.png" alt="">-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="footer">
      <div class="top">
        <a href="http://www.cyberpolice.cn/wfjb/">
          <img src="https://pt-starimg.didistatic.com/static/starimg/img/WgXFX7qoDL1616988549442.gif" alt="" style="margin-right: 10px">
        </a>
        <a href="http://www.12377.cn/">
          <img src="https://pt-starimg.didistatic.com/static/starimg/img/glTLpCqnny1616988549433.gif" width="100px" height="40px" alt="" >
        </a>
      </div>
      <div class="bottom">
        电话:400-6707-868 邮箱: hy@didiglobal.com
        <br/>
        Copyright ©️ 2020 <a target="_black" href="./pdf.pdf">天津快桔安运货运有限公司</a> 版权所有
        ICP备案： <a href="https://beian.miit.gov.cn/#/Integrated/index">津ICP备20004605号-1</a>
        <br>
        <a target="_black" href="https://pt-starimg.didistatic.com/static/starimg/img/xrPJiXfWaw1629447506705.jpeg">增值电信业务经营许可证：津B2-20200232</a>
      </div>
    </div>

    <el-dialog
        title="下载司机端App"
        :visible.sync="driverVisible"
        width="500px"
        :before-close="handleClose">
      <div class="qrcode_box">
        <qrcode :value="sijiduanurl" v-if="sijiduanurl" :options="{ size: 400 }"></qrcode>
      </div>

      <span slot="footer" class="dialog-footer">
            <el-button @click="driverVisible = false">取 消</el-button>
            <el-button type="primary" @click="driverVisible = false">确 定</el-button>
          </span>
    </el-dialog>
    <el-dialog title="ICP" :visible.sync="icpShow" width="700px" top="60px" :before-close="handleClose">
      <div class="qrcode_box">
        <img width="500px" height="" src="https://pt-starimg.didistatic.com/static/starimg/img/xrPJiXfWaw1629447506705.jpeg" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRcode from '@xkeshi/vue-qrcode'
export default {
  name: 'Home',
  data(){
    return {
      driverVisible: false,
      sijiduanurl: 'https://page.xiaojukeji.com/market/ddhy.html',
      icpShow: false,
    }
  },
  components :{
    qrcode : QRcode
  },
  methods: {

    handleClose(){
      this.driverVisible = false
      this.icpShow = false
      this.pdfVisible = false
    },
    /** 下边俩不要删， 有可能还要加回来的
     *
     */
    huozhu(){
      this.$router.push({
        name: 'registered'
      })
      // window.location.href = 'https://z.didi.cn/freightApp'
    },
    pingtai(){
      window.location.href = 'http://prestar.xiaojukeji.com/g/work/home#/'
    }
  }
}
</script>

<style scoped lang="scss">
.home{
  font-family: PingFangSC-Regular;
  .page_header{
    padding:1.5rem 3rem;
    background: #fff;
    img{
      height: 2.8rem;
    }
  }
  .banner{
    img{
      width: 100%;
      vertical-align: bottom;
    }
  }
  .card{
    .item{
      padding: 10rem 28rem 10rem 18rem;
      display: flex;
      .left{
        flex: 1;
        .l_text1{
          font-family: PingFangSC-Medium;
          font-size: 3.6rem;
          color: #333;
          padding-bottom: 1rem;
          font-weight: bold;
        }
        .l_text2{
          font-family: PingFangSC-Medium;
          font-size: 2.2rem;
          color: #333;
          padding-bottom: 3.2rem;
          font-weight: bold;
        }
        .l_text3{
          font-size: 2.2rem;
          color: #676767;
          padding-bottom: 1rem;
        }
      }
      .right{
        flex: 1;
        .img{
          width: 681px;
        }
      }
    }
  }
  //.setting_card{
  //  padding: 60px 0;
  //  background: black;
  //  display: flex;
  //  justify-content: center;
  //  .item{
  //    width: 310px;
  //    height: 310px;
  //    background: #fff;
  //    border-radius: 5px;
  //    overflow: hidden;
  //    margin-right: 30px;
  //    cursor: pointer;
  //    &:last-child{
  //      margin-right: 0;
  //    }
  //    img{
  //      width: 100%;
  //    }
  //  }
  //}
  .footer{
    background: #333;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    .top{
      padding-bottom: 1rem;
    }
    .bottom{
      text-align: center;
      color: #CFCFCF;
      a{
        color: #CFCFCF;
      }
    }
  }

  .qrcode_box{
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
