import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '快桔信息'
    }
  },
  {
    path: '/registered',
    name: 'registered',
    component: () => import(/* webpackChunkName: "about" */ '../views/registered.vue'),
    meta: {
      title: '货主注册/登录'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "about" */ '../views/agreement.vue'),
    meta: {
      title: '滴滴网络货运服务协议'
    }
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, form, next) => {
  console.log(to)
  document.title = to.meta.title
  next()
})
export default router
